/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from 'react';
import { graphql } from 'gatsby';
import { Box } from '@chakra-ui/react';
import { SearchResults } from '../../components/SearchResults';
import SEO from '../../components/SEO';
import { HeaderStyles, SearchHeaderStyles } from '../search';
import PaginationSearchAdvice from '../../components/PaginationSearchAdvice';
import { PageStyles } from '..';

export default function SearchPostsPage({ location, data }) {
    const adviceContents = data.adviceContents.nodes;
    const search = location ? location.search : null;
    let searchTerm;
    let page = 1;
    if (search.length > 1 && search.charAt(2) === '=') {
      const ampersandIndex = search.indexOf('&');
      searchTerm = ampersandIndex !== -1 ? search.slice(3, ampersandIndex) : search.slice(3);
      if (ampersandIndex !== -1) {
        page = parseInt(search.slice(ampersandIndex + 3));
      }
    } else {
      searchTerm = null;
    }
  if (!searchTerm) {
    return <HeaderStyles><span className="bullet">&bull;</span> Your search term didn't make it. Try again!</HeaderStyles>
  }
  const searchTermsArr = searchTerm?.toLowerCase().split('-');

  const adviceFiltered = [...adviceContents].filter(advice => {
    let isMatch = false;
    searchTermsArr.find(term => {
      const adviceCategoriesArr = advice.categories.map(category => category.category);
      const regEx = (new RegExp(term));
      const termMatched = regEx.test(advice.title.toLowerCase()) || regEx.test(advice.content.toLowerCase()) || regEx.test(advice.sourceTitle?.toLowerCase()) || regEx.test(advice.comedian.firstName.toLowerCase()) || regEx.test(advice.comedian.lastName.toLowerCase());   
      if (termMatched) {
        isMatch = true;
      }
      if (!termMatched) {
        adviceCategoriesArr.find(category => {
          if (regEx.test(category.toLowerCase())) {
            isMatch = true;
            return true;
          }
        })
      }
    })
    if (isMatch) {
      return advice;
    }
  });
  const { length } = adviceFiltered;
  const advicePerPage = parseInt(process.env.GATSBY_ADVICE_PER_PAGE);
  const startIndex = page * advicePerPage - advicePerPage;
  const endIndex = startIndex + advicePerPage;
  const adviceSliced = [...adviceFiltered].slice(startIndex, endIndex);

  return (
    <>
        <SEO title="Search posts | Yuks University" />
        <PageStyles>
          <SearchHeaderStyles>
            <SearchResults
                resultsArr={adviceSliced}
                searchTerm={searchTerm}
                type="Post"
                totalLength={length}
                page={page}
            />
            {length > advicePerPage && (
              <PaginationSearchAdvice
                pageSize={parseInt(process.env.GATSBY_ADVICE_PER_PAGE)}
                totalCount={length}
                currentPage={page || 1}
                skip={startIndex}
                base="/search/posts"
                searchTerm={searchTerm}
              />
            )}
          </SearchHeaderStyles>
        </PageStyles>
    </>
  );
}

export const query = graphql`
  query {
    adviceContents: allSanityAdviceContent(
      sort: { fields: _createdAt, order: ASC }
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        content
        sourceLink
        sourceTitle
        comedian {
          id
          firstName
          lastName
          slug {
            current
          }
          avatar {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        categories {
          id
          slug {
            current
          }
          category
        }
        _createdAt
      }
    }
  }
`;