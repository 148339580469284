import React from 'react';
import { Link } from 'gatsby';
import { Box } from '@chakra-ui/react';
import { PaginationStyles } from './Pagination';
import { getHalfwayPage } from '../utils/getHalfwayPage';
import { generateId } from '../utils/generateId';
import { getPagesArr } from '../utils/getPagesArr';

export default function PaginationSearchAdvice({
  pageSize,
  totalCount,
  currentPage,
  skip,
  base,
  searchTerm,
}) {
  // make some variables
  const totalPages = Math.ceil(totalCount / pageSize);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasNextPage = nextPage <= totalPages;
  const hasPrevPage = prevPage >= 1;
  const pagesArr = getPagesArr(totalPages, currentPage);
  return (
    <PaginationStyles>
      <Link
        className="button"
        title="Prev Page"
        disabled={!hasPrevPage}
        to={`${base}/?s=${searchTerm}&p=${prevPage}`}
      >
        <span className="arrow">←</span> Prev
      </Link>
      <Box className="pages-flex">
        {pagesArr.map((p, i) => (
          <React.Fragment key={generateId()}>
            {p !== '...' && (
              <Link
                className={
                  currentPage === p ? 'current page-number' : 'page-number'
                }
                to={`${base}/?s=${searchTerm}&p=${p > 0 ? p : 1}`}
              >
                {p}
              </Link>
            )}
            {p === '...' && (
              <Link
                className="page-number"
                to={`${base}/?s=${searchTerm}&p=${getHalfwayPage(
                  i,
                  currentPage,
                  totalPages
                )}`}
              >
                {p}
              </Link>
            )}
          </React.Fragment>
        ))}
      </Box>
      <Link
        className="button"
        title="Next Page"
        disabled={!hasNextPage}
        to={`${base}/?s=${searchTerm}&p=${nextPage}`}
      >
        Next <span className="arrow">→</span>
      </Link>
    </PaginationStyles>
  );
}
